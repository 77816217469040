import React, { useState, useEffect } from 'react';
import './HeroSection.css'; // Ensure you have this CSS file

const h1Messages = [
    "Welcome to OpenAI GPT Tech",
    "Empower your business with AI",
    "Innovate with cutting-edge technology",
    "Transform your world with AI solutions",
    "Revolutionize your strategies with AI",
    "Elevate your operations with smart AI",
    "Experience the future of artificial intelligence",
    "Unlock new potentials with AI",
    "Crafting the future with AI",
    "Leading the way in AI technology",
    "Your gateway to advanced AI",
    "Innovating for a smarter tomorrow",
    "Building intelligent solutions for you",
    "AI: Transforming the way we work",
    "Harness the power of AI with us",
    "Redefine possibilities with AI",
    "AI: Your partner in progress",
    "Bringing AI to life",
    "Explore the AI difference",
    "Your success, powered by AI",
];

const h2Messages = [
    "Want to enhance your skills in this digital world?",
    "Ready to explore the future of technology?",
    "Join us and lead the AI revolution",
    "Step into the world of advanced AI",
    "Elevating businesses with smart technology",
    "AI: The key to unlocking new opportunities",
    "Discover the power of artificial intelligence",
    "AI: Redefining innovation and creativity",
    "Transformative AI solutions for every need",
    "Empowering innovation with AI",
    "Join the AI evolution today",
    "Leading change with AI-driven solutions",
    "AI: The future of intelligent business",
    "Unleash your potential with AI",
    "Experience the power of intelligent technology",
    "AI: Changing the game in business",
    "Driving success through AI innovation",
    "Your journey into AI starts here",
    "AI: The new era of technology",
    "Shape your future with AI",
];


const HeroSection = () => {
    const [h1MessageIndex, setH1MessageIndex] = useState(0);
    const [h2MessageIndex, setH2MessageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setH1MessageIndex((prevIndex) => (prevIndex + 1) % h1Messages.length);
            setH2MessageIndex((prevIndex) => (prevIndex + 1) % h2Messages.length);
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="jumbotron hero-section">
            <div className="hero-particles">
                {/* Particle/Bubble effect */}
                <div className="particle bubble1"></div>
                <div className="particle bubble2"></div>
                <div className="particle bubble3"></div>
                <div className="particle bubble4"></div>
                <div className="particle bubble5"></div>
                <div className="particle bubble6"></div>
                <div className="particle bubble-hover"></div> {/* Bubble that appears on hover */}
            </div>
            <h1 className="display-4 slide-in">{h1Messages[h1MessageIndex]}</h1>
            <h2 className="display-8">{h2Messages[h2MessageIndex]}</h2>
            <p className="lead slide-in">
                Enhance your life and business with OpenAI GPT Tech services.
            </p>
        </div>
    );
};

export default HeroSection;
