import React from 'react';
import { Link } from 'react-router-dom';
import './MainSection.css';

const MainSection = () => {
    return (
        <main className="main-section">
            <h2>Welcome to OpenAI GPT Tech</h2>
            <p>Integrate cutting-edge AI services into your business with ease.</p>
            <div className="cta-buttons">
                <Link to="/join" className="btn-link">
                    <button className="btn-join">Join Now</button>
                </Link>
                <Link to="/services" className="btn-link">
                    <button className="btn-services">Our Services</button>
                </Link>
                <Link to="/buyagpt" className="btn-link">
                    <button className="btn-buy">Buy A GPT</button>
                </Link>
            </div>
            {/* Optional: Include images or icons here */}
        </main>
    );
};

export default MainSection;
