import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css'; // Adjust this path if necessary

const Contact = () => {
    const [contactData, setContactData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [messageSent, setMessageSent] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactData({
            ...contactData,
            [name]: value
        });
    };

    const sendEmail = () => {
        const serviceId = 'service_8dwpo93'; // Your email service ID
        const templateId = 'template_bjfkivj'; // Your template ID
        const userId = 'jN4kMHcVbPiVGqfnk'; // Your user ID (Should be secured)

        const templateParams = {
            from_name: contactData.name,
            reply_to: contactData.email,
            subject: contactData.subject,
            message: contactData.message
        };

        emailjs.send(serviceId, templateId, templateParams, userId)
            .then(response => {
                if (response.status === 200) {
                    setMessageSent(true);
                } else {
                    console.error('Email sending failed:', response.text);
                    setError('Failed to send email. Please try again.');
                }
            })
            .catch(err => {
                console.error('Error sending email:', err);
                setError('An error occurred. Please try again.');
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        sendEmail();
    };

    return (
        <div className="contact-section">
            {messageSent ? (
                <p>Thank you for contacting us! We've received your message.</p>
            ) : (
                <div>
                    <h2>Contact Us</h2>
                    <form onSubmit={handleSubmit} className="contact-form">
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={contactData.name}
                            onChange={handleChange}
                            required
                        />

                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={contactData.email}
                            onChange={handleChange}
                            required
                        />

                        <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            value={contactData.subject}
                            onChange={handleChange}
                            required
                        />

                        <textarea
                            name="message"
                            placeholder="Your Message"
                            value={contactData.message}
                            onChange={handleChange}
                            required
                        ></textarea>

                        <button type="submit">Send Message</button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
            )}
        </div>
    );
};

export default Contact;
