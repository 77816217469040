import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import About from './components/About';
import Contact from './components/Contact';
import JoinNow from './components/JoinNow';
import Services from './components/Services';
import Donations from './components/Donations';
import BuyAGPT from './components/BuyAGPT';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainSection from './components/MainSection';
import Footer from './components/Footer';
import HeroSection from './components/HeroSection';


function App() {
  return (
    <Router>
      <Header />
      <HeroSection />
      
      <Routes>
        <Route path="/" element={<MainSection />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/join" element={<JoinNow />} />
        <Route path="/services" element={<Services />} />
        <Route path="/donations" element={<Donations />} />
        <Route path="/buyagpt" element={<BuyAGPT />} />
      </Routes>
      <Footer />
    </Router>
    
  );
}

export default App;
