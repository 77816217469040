import React from 'react';
import './Donations.css';

const Donations = () => {
    const solanaAddress = '7mhe5xmiUCKoqNs9mkh83FobjzJXH49tTRumT2jHXr9i';

    const copyToClipboard = () => {
        navigator.clipboard.writeText(solanaAddress).then(() => {
            // You can add some notification to the user here if needed
            alert('Address copied to clipboard!');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    return (
        <div className="donations-section">
            <h2>Support Our Development</h2>
            <p>Love our service? You can support us by buying a coffee or making a donation.</p>
            <div className="donation-options">
            <p>Click the button to copy the Solana address:</p>
                <button 
                    className="donation-button"
                    onClick={copyToClipboard}
                >
                    Buy Us a Coffee
                </button>
            </div>
          
        </div>
    );
};

export default Donations;
