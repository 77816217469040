import React from 'react';
import './Footer.css'; // Make sure your CSS file is linked

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p className="footer-title">OpenAI GPT Tech</p>
                <p>Enhancing your business with cutting-edge AI technology.</p>
                <div className="contact-info">
                    <p><i className="fas fa-phone"></i> <a href="tel:+1-948-204-7987">+1-948-204-7987</a></p>
                    <p><i className="fas fa-envelope"></i> <a href="mailto:business@openaigpt.tech">business@openaigpt.tech</a></p>
                   
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} OpenAI GPT Tech. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
