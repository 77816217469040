import React from 'react';
import { Link } from 'react-router-dom';
import './Services.css'; // Link to your CSS file for styling

const Services = () => {
    return (
        <div className="services-section">
            <h2>Our Advanced GPT-Based Solutions</h2>
            <p>
                At the forefront of AI technology, we specialize in creating custom GPT solutions tailored 
                to your personal and business needs. Our services extend beyond mere software, 
                integrating cutting-edge AI into your physical workspace and digital platforms. 
            </p>
            <div className="services-list">
                <div className="service">
                    <h3>Custom GPT for Personal Life</h3>
                    <p>
                        Enhance your daily life with a personal AI assistant. 
                        From organizing schedules to providing intelligent recommendations, 
                        our custom GPT solutions are designed to adapt to your personal needs.
                    </p>
                    <p>Price: $150</p>
                </div>
                <div className="service">
                    <h3>Custom GPT for Your Small Business</h3>
                    <p>
                        Transform your small business with AI-driven insights and automations. 
                        Our solutions can handle customer interactions, analyze data trends, 
                        and streamline operations, giving you a competitive edge.
                    </p>
                    <p>Price: $250</p>
                </div>
                <div className="service">
                    <h3>Custom GPT for Your Large Business</h3>
                    <p>
                        Elevate your large business with scalable AI solutions. From advanced analytics 
                        to automated decision-making processes, our GPT technology can significantly 
                        boost efficiency and innovation.
                    </p>
                    <p>Price: $500</p>
                </div>
                <div className="service">
                    <h3>Misc Custom GPT for Your Work</h3>
                    <p>
                        Tailored GPT applications for specific work-related tasks, from specialized skill assistance 
                        to comprehensive AI management systems. Our range includes:
                    </p>
                    <ul>
                        <li>One Skill - $100</li>
                        <li>Five Skills - $350</li>
                        <li>Ten Skills - $500</li>
                        <li>God-View GPT - $1000</li>
                    </ul>
                </div>
            </div>
            <div className="training-section">
                <h3>AI Integration and Employee Training</h3>
                <p>
                    We don't just provide AI solutions; we ensure seamless integration into your existing systems 
                    and offer comprehensive training for your team. Equip your employees with the skills to leverage 
                    AI technology effectively, ensuring a smooth transition and maximizing the benefits of our GPT services.
                </p>
            </div>
            <Link to="/contact" className="btn-link">
                    <button className="btn-buy">Contact for Services</button>
                </Link>
        </div>
    );
};

export default Services;
