import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';


const About = () => {
    return (
        <div className="about-section">
            <h2 className="about-title">About OpenAI GPT Tech</h2>
            <p>
                At OpenAI GPT Tech, we believe in harnessing the power of artificial intelligence to revolutionize everyday life and business operations. Our innovative solutions are designed to enhance efficiency, creativity, and decision-making across various sectors.
            </p>
            <div className="about-details">
                <div className="about-personal">
                    <h3>Personal Integration</h3>
                    <p>
                        For individuals, our AI technology offers personalized assistance, learning enhancement, and lifestyle improvements, making everyday tasks more manageable and enjoyable.
                    </p>
                </div>
                <div className="about-small-business">
                    <h3>Small Business Growth</h3>
                    <p>
                        Small businesses can leverage AI to optimize operations, gain insightful analytics, and provide tailored customer experiences, leading to sustainable growth and competitiveness.
                    </p>
                </div>
                <div className="about-big-business">
                    <h3>Large Scale Transformation</h3>
                    <p>
                        For large corporations, our AI solutions facilitate large-scale automation, strategic planning, and global market analysis, driving innovation and a significant edge in the ever-evolving business landscape.
                    </p>
                </div>
            </div>
            <p className="about-mission">
                "
                Our mission is to empower communities and businesses of all sizes with AI technology, making it accessible, user-friendly, and transformative.
                "
            </p>
            <Link to="/join" >
                    <button className="join-now-button">Join Now</button>
                </Link>
        </div>
    );
};

export default About;
