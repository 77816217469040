import React, { useEffect } from 'react';
import './BuyAGPT.css'; // Ensure the CSS file is correctly linked
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the CSS




const BuyAGPT = () => {
    useEffect(() => {
        AOS.init({
            // You can also pass in options
            duration: 2000,
        });
    }, []);

    const products = [
        {
            id: 1,
            name: "Personal GPT Assistant",
            description: "Designed for individual use, this GPT assistant helps with daily tasks, schedules, and personal queries. Ideal for enhancing your day-to-day productivity and organization.",
            price: 150,
            image: 'gpt1.png', // Replace with actual image path
            url: 'https://openaigpt.tech/contact' // Specific URL for this product
        },
        {
            id: 2,
            name: "Small Business GPT Solution",
            description: "Perfect for small businesses, this GPT solution offers customer service automation, data analysis, and marketing insights to drive growth and efficiency.",
            price: 250,
            image: 'gpt2.png', // Replace with actual image path
            url: 'https://openaigpt.tech/contact' // Specific URL for this product
        },
        {
            id: 3,
            name: "Enterprise GPT System",
            description: "This comprehensive GPT system is tailored for large enterprises, providing advanced AI capabilities for large-scale data analysis, automation, and strategic decision-making.",
            price: 500,
            image: 'gpt3.png', // Replace with actual image path
            url: 'https://openaigpt.tech/contact' // Specific URL for this product
        },
        {
            id: 4,
            name: "Custom GPT for Your Work",
            description: "A versatile, customizable GPT solution tailored to your specific needs. Choose from different tiers based on the number of skills you require:",
            priceOptions: [
                { tier: "One Skill", price: 100 },
                { tier: "Five Skills", price: 350 },
                { tier: "Ten Skills", price: 500 },
                { tier: "God-View GPT", price: 1000 }
            ],
            image: 'gpt4.png', // Replace with the actual image path
            url: 'https://openaigpt.tech/contact' // Specific URL for this product
        }
    ];

    return (
        <div className="buyagpt-section">
            <h2>Our GPT Products</h2>
            <div className="gpt-products">
                {products.map(product => (
                    <div className="gpt-product" key={product.id}>
                        <h3>{product.name}</h3>
                        <img src={product.image} alt={product.name} className="product-image" />
                        <p>{product.description}</p>
                        {product.priceOptions ? (
                            <div>
                                {product.priceOptions.map((option, index) => (
                                    <p key={index} className="price">{option.tier} - ${option.price}</p>
                                ))}
                            </div>
                        ) : (
                            <p  data-aos="fade-in" className="price">Price: ${product.price}</p>
                        )}
                        <a href="/contact" target="_blank" rel="noopener noreferrer">
                            <button>Buy Now</button>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BuyAGPT;
