import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './Header.css';

function Header() {
  return (
    <div className="container-flex">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand as={NavLink} to="/">
            <img
              src="/logo.png" // Path to your logo image
              width="30"      // Adjust the width as needed
              height="30"     // Adjust the height as needed
              className="d-inline-block align-top logo" // Bootstrap classes for alignment
              alt="OpenAI GPT Tech Logo"          // Alt text for accessibility
            />
            {' '}OpenAI GPT Tech
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/" end>Home</Nav.Link>
              <Nav.Link as={NavLink} to="/about">About</Nav.Link>
              <Nav.Link as={NavLink} to="/contact">Contact</Nav.Link>
              <NavDropdown title="Start Here" id="basic-nav-dropdown">
                <NavDropdown.Item as={NavLink} to="/join">Join Now</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/services">Services</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/donations">Donations</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={NavLink} to="/buyagpt">Buy A GPT Today</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
