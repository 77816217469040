import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './JoinNow.css';


const JoinNow = () => {
    const [email, setEmail] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [error, setError] = useState(null);

    const sendEmail = () => {
        const serviceId = 'service_8dwpo93'; // Your email service ID
        const templateId = 'template_bjfkivj'; // Your template ID
        const userId = 'jN4kMHcVbPiVGqfnk'; // Your user ID (Should be secured)

        const templateParams = {
            to_name: 'Your Name or Recipient Name', // Name of the recipient of the email
            from_name: 'User', // You can give a generic name or use any other field for sender's name
            message: `Email from user: ${email}` // This includes the user's email in the message
        };

        emailjs.send(serviceId, templateId, templateParams, userId)
            .then(response => {
                if (response.status === 200) {
                    setMessageSent(true);
                } else {
                    console.error('Email sending failed:', response.text);
                    setError('Failed to send email. Please try again.');
                }
            })
            .catch(err => {
                console.error('Error sending email:', err);
                setError('An error occurred. Please try again.');
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        sendEmail();
    };

    return (
        <div className="join-now-section">
            {messageSent ? (
                <p>Thank you for joining! We've received your email: {email}</p>
            ) : (
                <div>
                    <h2>Join OpenAI GPT Tech Services</h2>
                    <p>Sign up to unlock the full potential of AI for your personal and business needs.</p>
                    <form onSubmit={handleSubmit} className="join-now-form">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button type="submit">Join Now</button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
            )}
        </div>
    );
};

export default JoinNow;
